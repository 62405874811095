var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { attrs: { className: "template-wrap" } }, [
      _c(
        "div",
        { staticClass: "employee-category", class: { active: _vm.isGroup() } },
        [
          _c("div", [
            !_vm.isGroup() && !_vm.isVacant()
              ? _c("img", {
                  staticClass: "employee-image",
                  attrs: { src: _vm.setImage(), alt: "" },
                  on: {
                    click: function($event) {
                      return _vm.$router.push("/staff-bank/" + _vm.uid)
                    }
                  }
                })
              : _vm._e()
          ]),
          _c(
            "div",
            {
              staticClass: "employee_name  flex",
              staticStyle: {
                "padding-bottom": "1px",
                "padding-top": "2px!important"
              }
            },
            [
              _c("div", { class: { employee: !_vm.isGroup() } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.getEmployeeName().slice(0, 25) +
                        (_vm.getEmployeeName().length > 25 ? "..." : "")
                    ) +
                    " "
                )
              ]),
              _vm.isLocum()
                ? _c(
                    "span",
                    {
                      staticClass: " text-dark font-weight-700",
                      staticStyle: {
                        padding: "8px",
                        width: "20px",
                        background: "#CCE6F4",
                        "border-radius": "8px",
                        "font-weight": "bold"
                      }
                    },
                    [_vm._v(" L ")]
                  )
                : _vm._e()
            ]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }