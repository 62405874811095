<template>
  <div class="chell">
    <!-- <div class="d-flex" >
      <span class="mt-1" >{{momentFormat(data.StartTime, 'HH:mm') +' - ' }}</span> 
    </div>
    <div>
      <span>{{momentFormat(data.EndTime,'HH:mm')}}</span>  
    </div>   -->
      
    <div class="template-wrapper">
      <!-- <span>{{data.reason}}</span>   -->
        <!-- <p class="text-light font-weight-bold" >{{data.reason}}</p> -->
    </div>
    <!-- <div class="template-wrapper" >
      
    </div> -->
  </div>

</template>

<script>

import MomentMixin from '@/mixins/MomentMixin'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import {BAlert} from 'bootstrap-vue'
export default {
  name: 'weekTemplate',
  mixins: [ErrorMessages, SuccessMessage, MomentMixin],
  comments:{
    BAlert,

  },
  data(){
    return{
      data:''
    }
  },

  methods:{
    getTimeString: function (value) {
      return instance.formatDate(value, { skeleton: 'hm' });
    }
  },
  mounted () {
    if (this.data.applications_count !==0){
      this.data.color ='#EFA12C'
    }
  }
}
</script>

<style lang="scss" scoped>
.chell{
  width: 100% !important;

}
// .template-wrapper{
//   padding: 10px;
//   height: 100%;
//   font-size: 12px;

// }
.e-schedule .e-timeline-view .e-appointment, .e-schedule .e-timeline-month-view .e-appointment {
  color: #fff;
  overflow: hidden;
  position: absolute;
  height: 5px !important;
  border-radius: 0px;
  margin-top: 5px;
  // width: 80px !important;

}

.borderBind{
  border-style: dashed;
  background-color: transparent;
  border-radius: 4px;
} 
.template-wrapper{
  height: 100%;
  width: 100%;
  font-size: 12px;
  overflow: hidden; 
  text-overflow: ellipsis; 
}
</style>
