<template>
  <b-card no-body>
    <b-card-body>
        <b-overlay
            :show="graphLoading"
            rounded="sm"
        >
            <b-row>
                <b-col cols="12" sm="5">   
                </b-col>
            </b-row> 
            <div class="mb-2">
            <h3 class="text-center">Annual Leave Overview {{annualYear}}</h3>
            </div>
            <div class="ml-2 ">
                <b-row>
                    <b-col cols="1">
                    </b-col>
                    <b-col cols="10" >
                        <v-frappe-chart
                            class="d-flex justify-content-center ml-2"
                            v-bind:class="{test:cssSet}"
                            type="heatmap"
                            :data="allLeaves"
                            :colors="['#E5E5E5','#1B9AAA', '#9DE6F4', '#00C49A', '#EFA12C']"
                            :height="130"
                            :radius="6"
                            :start="new Date(callenderStartDate)"
                            :end="new Date(callenderEndDate)"
                            :tooltipOptions="false"
                            />
                        
                    </b-col>
                    <b-col cols="1">
                    </b-col>
                </b-row>
            </div>
            <div class="mt-5">
                <b-row>
                    <b-col cols="12" sm="11" class="float-right" style="font-size: 12px">
                        <div>
                            <b-row class="float-right">
                            <b-col>
                                <div class="d-flex align-items-center ">
                                <b-avatar style="background:#00C49A !important" rounded size="10" class="mt-n1">
                                    <feather-icon icon="CheckIcon" size="1" />
                                </b-avatar>
                                <div class="ml-1">
                                    <div class="list-inline mb-0" >
                                    <p class="mb-0 font-weight-bolder">Today</p>
                                    <p></p>
                                    </div>

                                </div>
                                </div>
                            </b-col>
                            <b-col >
                                <div class="d-flex align-items-center" style="width: 120px">
                                <b-avatar style="background:#EFA12C !important" rounded size="10" class="mt-n1">
                                    <feather-icon icon="CheckIcon" size="1" />
                                </b-avatar>
                                <div class="ml-1">
                                    <div class="list-inline mb-0">
                                    <p class="mb-0 font-weight-bolder">Bank Holiday</p>
                                    <p></p>
                                    </div>

                                </div>
                                </div>
                            </b-col>
                            <b-col>
                                <div class="d-flex align-items-center" style="width: 80px">
                                <b-avatar style="background:#1B9AAA !important" rounded size="10" class="mt-n1">
                                    <feather-icon icon="CheckIcon" size="1" />
                                </b-avatar>
                                <div class="ml-1">
                                    <div class="list-inline mb-0">
                                    <p class="mb-0 font-weight-bolder">On leave</p>
                                    <p></p>
                                    </div>
                                </div>
                                </div>
                            </b-col>
                            <b-col>
                                <div class="d-flex align-items-center " style="width: 130px">
                                <b-avatar style="background:#9DE6F4 !important" rounded size="10" class="mt-n1">
                                    <feather-icon icon="CheckIcon" size="1" />
                                </b-avatar>
                                <div class="ml-1">
                                    <div class="list-inline mb-0">
                                    <p class="mb-0 font-weight-bolder">No leaves allowed</p>
                                    <p></p>
                                    </div>
                                </div>
                                </div>
                            </b-col>
                            </b-row>
                        </div>
                    </b-col>
            </b-row>
            </div>
        </b-overlay>  
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BDropdown, BDropdownItem,
BRow, BCol, BAvatar, BOverlay} from 'bootstrap-vue'
import moment from 'moment'
import MomentMixin from '@/mixins/MomentMixin'
import AdminLeaveAPI from '@/apis/modules/leave_managment_admin'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import DateDiff from 'date-diff'
export default {
  mixins: [MomentMixin, ErrorMessages, SuccessMessage],
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BRow, BCol,
    BAvatar,
    BOverlay,
    BRow,
    BCol
  },
  watch:{

  },
  data() {
    return {
      graphLoading:false,
      graphStartDate:'',
      graphEndDate:'',
      start:'',
      end:'',
      annualYear:'',
      callenderDates: '',
      callenderStartDate:'',
      callenderEndDate:'',
      allLeaves:{},
      userLeaves:{},
      practiceLeaves:{},
      today:{},
      cssSet:'',
      bankLeaves:{},
      items: {
        '1639012425': 4,
        '1635705000': 3,
        '1635953105': 2,
        '1633267725': 1,
      },
    }
  },
  methods:{
      async getGraphDetails(){
        try{
            this.graphLoading = true
            let date = new Date();
            let start = new Date(date.getFullYear(), 0)
            let end = new Date(date.getFullYear(), + 12, 0)
            this.start = this.momentFormat(start, 'YYYY-MM-DD')
            this.end = this.momentFormat(end, 'YYYY-MM-DD')
            const Response = await AdminLeaveAPI.getGraphDetails();
            let userLeaves = Response.data.data.user_leaves
            let practiceLeaves = Response.data.data.practice_leaves
            let leaveStartDate = Response.data.data.leave_start_date[0]

            let callenderDate = new Date()
                this.callenderStartDate = new Date(callenderDate.getFullYear(), 0)
                this.callenderEndDate = new Date(callenderDate.getFullYear(), + 12, 0)
                
            // if(leaveStartDate !== '0000-00-00 00:00:00'){
            //     let callenderDate = new Date(leaveStartDate);
            //     let year = callenderDate.getFullYear()
            //     let month = callenderDate.getMonth()
            //     let day = callenderDate.getDate()
            //     this.callenderStartDate = callenderDate
            //     this.callenderEndDate = new Date(year + 1, month, day - 1);
            // }else{
            //     let callenderDate = new Date()
            //     this.callenderStartDate = new Date(callenderDate.getFullYear(), 0)
            //     this.callenderEndDate = new Date(callenderDate.getFullYear(), + 12, 0)
            // }
            await this.getCallenderUserDates(userLeaves)
            await this.getCallenderPracticeDates(practiceLeaves)
            await this.getCallenderBankDates()
            await this.getTodayDate()
            await this.mergeCallenderHolidays()
            this.graphLoading = false
        }catch(error){
            this.graphLoading = false
            this.convertAndNotifyError(error)
        }
      },
      async getCallenderUserDates(array){
        try{
            let newArray = await this.calculateIsDayDates(array)

            const dataArray = newArray.map((x) => ({
                unix: Math.floor(new Date(x.start).getTime()/1000.0),
                count: moment(new Date(x.start)).dayOfYear(),
            }))
            let obj = {}
            obj = dataArray.reduce((o, key) => Object.assign(o, {[key.unix]: 1}), {})
            this.userLeaves = obj
        }catch(error){
            this.convertAndNotifyError(error)
        }

      },
      async getCallenderPracticeDates(array){
        try{
            let newArray = await this.calculateIsDayDates(array)
            const dataArray = newArray.map((x) => ({
            unix: Math.floor(new Date(x.start).getTime()/1000.0),
            count: moment(new Date(x.start)).dayOfYear(),
            }))
            let obj = {}
            obj = dataArray.reduce((o, key) => Object.assign(o, {[key.unix]: 2}), {})
            this.practiceLeaves = obj
        }catch(error){
            this.convertAndNotifyError(error)
        }
      },
      async getCallenderBankDates(){
          try{
            const Response = await AdminLeaveAPI.getUKPublicHolidays();
            let dataArray = Response.data.map((x) => ({
                date: x.date
            }))
            let currentYearData = [];
            var date = new Date();
            let currentYear = date.getFullYear();
            for(let data of dataArray){
                let date =  new Date(data.date)
                let year = date.getFullYear()
                if(currentYear == year ){
                    let unix = Math.floor(new Date(data.date).getTime()/1000.0)
                    currentYearData.push(unix)
                }
            }
            let obj = {};
            obj = currentYearData.reduce((o, key) => Object.assign(o, {[key]: 4}), {})
            this.bankLeaves = obj
          }catch(error){
            this.convertAndNotifyError(error)
          }
      },
      getTodayDate(){
        try{
            var date = new Date();
            let today = Math.floor(new Date(date).getTime()/1000.0)
            this.todayDate = {
            [today]: 3
          }
        }catch(error){
            this.convertAndNotifyError(error)
        }
      },
      mergeCallenderHolidays(){
        this.allLeaves = {...this.userLeaves, ...this.practiceLeaves, ...this.bankLeaves, ...this.todayDate}
      },
      calculateIsDayDates(array){
        let newArray = array
            for(var i=0; i < newArray.length; i++){
            if(newArray[i].is_day){
                let dif = new DateDiff(new Date(newArray[i].end), new Date(newArray[i].start)).days()
                let counter = 0;
                while(counter < dif){
                    let obj = {};
                    var date = moment(newArray[i].start);
                    var addedDate = new Date( date + ((counter+1)*24*60*60*1000))
                    obj['start'] = this.momentFormat(addedDate, 'YYYY-MM-DD')
                    newArray.push(obj)
                    counter++;
                }
            }
            }
        return newArray;
      }
  },
  async mounted(){
    var date = new Date();
    var year = date.getFullYear()
    this.annualYear = year
    this.callenderDates = date
    this.cssSet = true
    await this.getGraphDetails()
    // var day = moment.unix(1318781876);
    // var day = moment(1318781876406);
    // console.log(this.momentFormat(new Date(), 'unix'))
    // console.log(Math.floor(new Date('November 01, 2021 00:00:00').getTime()/1000.0))
    // console.log('Today', moment(new Date()).dayOfYear())

    // console.log(day.format('dddd MMMM Do YYYY, h:mm:ss a'));
  }
}
</script>
<style lang="scss">
    #frappe-chart-0{
        pointer-events: none;
        transform: scale(1.5) !important;
    }
    .test{
        pointer-events: none;
        transform: scale(1.5) !important;
    }
    .chart-legend{
        display: none;
    }
    
</style>
