<template>
  <b-card class="mp-0">
    <b-row>
      <b-col cols="" class="d-flex">
        <div>
          <b-form-group>
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                  id="icons-search"
                  v-model="filters['search']"
                  placeholder="Search"
                  autocomplete="off"
              />
            </b-input-group>
          </b-form-group>
        </div>
        <div class="ml-auto mb-1">
            <b-button
          size=""
          variant="white"
          class="btn-icon bg-primary text-white shadow-sm font-weight-bold"
          @click="toggleSideBars()"
          >
          + Add Leave
          </b-button>
        </div>
      </b-col>
      <b-col cols="12">
        <b-overlay
        :show="tableLoading"
        rounded="sm"
        >
        <b-table
            ref="table"
            :current-page="currentPage"
            :per-page="pagination.perPage"
            :fields="fields"
            :filter="filter"
            :filter-included-fields="filterOn"
            :items="staffList"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            hover
            class="mobile_table_css"
            responsive

        >
<!--          @click="viewHistory(data.item.user_id)"-->
          <template #cell(name)="data">
              <b-row class="center text-primary" @click="viewHistory(data.item)">
                  <b-avatar
                    size="40"
                    class="badge-minimal"
                    variant="light-primary"
                    :src="getUserImage(data.item.user_id)"
                  />
                  <p class="ml-1 font-weight-bolder pt-1 text-dark" >{{data.item.name}}</p>
                <br/>
              </b-row>
            </template>
            <template #cell(alocated_leave)="data">
              <b-row class="center" v-if="!staffListData[data.index].isEdit">
                <b-col class="mr-n4">
                  <p style="margin-top:10px" v-if="data.item.track_leave_on_day">
                    {{data.item.alocated_leave}} days
                  </p>
                  <p style="margin-top:10px" v-else>
                    {{data.item.alocated_leave}} hrs
                  </p>
                </b-col>
                <b-col class="">
                  <b-button
                    size="23"
                    variant="outline-white"
                    class="btn-icon bg-white text-primary"
                    @click="editLeaveDays(data, data.item.user_id, data.item.alocated_leave, data.item.track_leave_on_day, data.item.working_hours_per_day,data.item.used_days)"
                    >
                    <feather-icon icon="EditIcon" size="18" />
                  </b-button>
                </b-col>
              </b-row>
              <b-row v-else>
                <b-col sm="8" class="mb-0 mr-n4 ml-n1">
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        v-model="data.item.alocated_leave"
                        size="lg"
                        placeholder=""
                        type="number"
                      />

                      <b-input-group-append is-text >
                        <b-button
                      size="sm"
                      variant="outline-white"
                       class="btn-icon bg-white text-success">
                        <feather-icon style="cursor: pointer;" @click="editLeaveDays(data, data.item.user_id, data.item.alocated_leave, data.item.track_leave_on_day, data.item.working_hours_per_day,data.item.used_days)" icon="CheckIcon" size="15" />
                      </b-button>
                      </b-input-group-append>
                    </b-input-group>
                </b-col>
              </b-row>
            </template>
            <template #cell(used_days)="data">
              <b-row class="center" @click="viewHistory(data.item)">
                <b-col class="mr-n4">
                  <p style="margin-top:10px" v-if="data.item.track_leave_on_day">
                    {{parseFloat(data.item.used_days).toFixed(1)}} days
                  </p>
                  <p style="margin-top:10px" v-else>
                    {{data.item.used_days<0?data.item.used_days=0:''}}
                    {{parseFloat(data.item.used_days * data.item.working_hours_per_day).toFixed(1)}} hrs
                  </p>
                </b-col>
              </b-row>
            </template>
            <template #cell(remaining_days)="data">
              <b-row class="center" @click="viewHistory(data.item)">
                <b-col class="mr-n4">
                  <p style="margin-top:10px" v-if="data.item.track_leave_on_day">
                    {{parseFloat(data.item.remaining_days).toFixed(1)}} days
                  </p>
                  <p style="margin-top:10px" v-else>
                    {{data.item.remaining_days<0?data.item.remaining_days=0:''}}
                    {{parseFloat(data.item.remaining_days * data.item.working_hours_per_day).toFixed(1)}} hrs
                  </p>
                </b-col>
              </b-row>
            </template>
          <template #cell(last_leave)="data">
            <b-row class="center" @click="viewHistory(data.item)">
              <b-col>
                {{data.item.last_leave}}
              </b-col>

            </b-row>
          </template>
<!--          <template #cell(action)="data">-->
<!--                  <b-row class="center text-primary">-->
<!--                      <b-button-->
<!--                      size=""-->
<!--                      variant="white"-->
<!--                      class="btn-icon bg-white text-primary shadow-sm font-weight-bold"-->
<!--                      @click="viewHistory(data.item.user_id)"-->
<!--                      >-->
<!--                          Leave History-->

<!--                      </b-button>-->
<!--                      <span class="ml-2" style="margin-top:5px"><DropdownMenue :user-data="data.item"/></span>-->

<!--                  </b-row>-->

<!--              </template>-->

        </b-table>

        </b-overlay>
      </b-col>


      <!-- Pagination -->

      <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
        <span class="text-muted" v-if="pagination.totalRows !==0">Showing {{pagination.from}} to {{pagination.to}} of {{pagination.totalRows}} entries</span>
      </b-col>

      <!-- Pagination -->

      <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end" >

        <b-pagination
            v-model="currentPage"
            :per-page="pagination.perPage"
            :total-rows="pagination.totalRows"
            class="mb-0 mt-1 mt-sm-0 "
            first-number
            last-number
            next-class="next-item"
            prev-class="prev-item">

            <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>

            <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
            </template>

        </b-pagination>

      </b-col>
      <AddLeaveSidebar
          ref="AddLeaveSidebar"
          :is-add-leave-sidebar-active="isAddLeaveSidebarActive"
          @toggleSideBars="toggleSideBars()"
      />
    </b-row>
  </b-card>
</template>

<script>

import {BDropdown, BDropdownItem} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from "vue-select";
import flatPickr from 'vue-flatpickr-component'
import AddLeaveSidebar from '../sidebars/addLeaveSidebar.vue'
import DropdownMenue from './dropDownMenu.vue'
import leaveManagementAdmin from  '@/apis/modules/leave_managment_admin'
import MomentMixin from '@/mixins/MomentMixin'

import {
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    VBTooltip,
    BInputGroupPrepend,
    BButtonGroup,
    BOverlay

} from 'bootstrap-vue'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import Filter from '@/mixins/FilterMixin'


export default {
  components: {
    BCard,
    DropdownMenue,
    AddLeaveSidebar,
    flatPickr,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BInputGroupPrepend,
    BButtonGroup,
    BOverlay,
    vSelect,
    VBTooltip
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [ErrorMessages, SuccessMessage, MomentMixin, Filter],
  data() {
    return {
      staffListData:{},
      filters: {},
      isAddLeaveSidebarActive: false,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows:'' ,
        from:'',
        to:''
      },
      totalRows: 1,
      currentPage: 1,
      usedDays:'',
      editLeaveDaysShow:true,
      editNoOfLeaveDays:'',
      submittedTableLenght:0,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      tableLoading: false,
      date: '',
      filterOn: [],
      currentPracticeId:'',
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      user_data:{},
      fields: [
        {
          key: 'name',
          label: 'NAME',
          sortable: true
        },
        {
          key: 'alocated_leave',
          label: 'ALLOCATED LEAVE',
          sortable: true
        },
        {
          key: 'used_days',
          label: 'USED LEAVE',
          sortable: true
        },
        {
          key: 'remaining_days',
          label: 'REMAINING LEAVE',
          sortable: true
        },
        {
          key: 'last_leave',
          label: 'LAST LEAVE DATE',
          sortable: true
        },
        // {
        //   key: 'action',
        //   label: '',
        // },


      ],

    }
  },
  methods:{
    toggleSideBars() {
      this.$refs.table.refresh()
      this.isAddLeaveSidebarActive = !this.isAddLeaveSidebarActive
    },

    viewHistory(item){
      console.log(item)
      this.$router.push(`/staff-bank/${item.user_id}?tab_number=2`)
    },

    userDetails(data) {
       this.user_data = data
    },

    refreshTable(){
      this.$refs.table.refresh()
    },

    async staffList () {
      try {
        this.tableLoading = true
        const Response = await leaveManagementAdmin.staffList(this.filterQuery,'salaried-staff')
        // let currentPracticeId = Response.data.data[0].practice_id
        // let self = this
        this.staffListData  = Response.data.data.map((x) => ({
          user_id: x.user.id,
          name: x.user.first_name + ' ' + x.user.last_name,
          alocated_leave: x.practice.track_leave_on_days ? x.number_of_leaves_per_year : parseFloat(x.number_of_leaves_per_year * x.practice.working_hours_per_day).toFixed(1),
          used_days: ((x.number_of_leaves_per_year * x.practice.working_hours_per_day) - x.leave_takable_count)/x.practice.working_hours_per_day,
          remaining_days: x.number_of_leaves_per_year - ((x.number_of_leaves_per_year * x.practice.working_hours_per_day) - x.leave_takable_count)/x.practice.working_hours_per_day,
          working_hours_per_day: x.practice.working_hours_per_day,
          last_leave: x.user.leaves.length > 0 ? this.momentFormat(x.user.leaves.slice(-1)[0].end, 'DD-MMM-YY') : '-',
          job_role: x.job_role? x.job_role.name : 'N/A',
          telephone_no: x.user.phone_number? x.user.phone_number:'N/A',
          email:x.user.email?x.user.email:'',
          qualification:x.user.qalifications && x.user.qalifications.qalification ? x.user.qalifications.qalification.name:'',
          speciality:x.user.qalifications && x.user.qalifications.speciality ? x.user.qalifications.speciality.name:'',
          it_system:x.user.qalifications && x.user.qalifications.it_system ? x.user.qalifications.it_system.name:'',
          smart_card_number:x.user.qalifications && x.user.qalifications.smart_card_number ? x.user.qalifications.smart_card_number:'',
          skills:x.user.qalifications && x.user.qalifications.skills ? x.user.qalifications.skills:'',
          unavailabilities:x.user.un_availabilities ? x.user.un_availabilities:'',
          track_leave_on_day: !!x.practice.track_leave_on_days,
          isEdit: false
        }))
        this.tableLoading = false
        const paginationResponse = Response.data.meta
        this.currentPage = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage = paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to
        return this.staffListData
      }catch (error) {
        this.tableLoading = false
        this.convertAndNotifyError(error)
      }
    },
    async editLeaveDays(data, id, count, onDays, workingHours, usedLeave){
      try{
        if(data.item.isEdit){
          !onDays?usedLeave=usedLeave*workingHours:''
          if(usedLeave>count){
            this.showErrorMessage('Remaining leave cannot be greater than Allocated leave' )
          }else {
            let payload = {}
            payload.user_id = id
            if(onDays){
              payload.number_of_leaves_per_year = count
            }else{
              payload.number_of_leaves_per_year = count/workingHours
            }
            await leaveManagementAdmin.updateUsersNumberOfLeaves(payload)
            data.item.isEdit = !data.item.isEdit
            this.$refs.table.refresh()
          }

        }else{
          data.item.isEdit = !data.item.isEdit
        }
      }catch(error){
        this.loading = false
        data.item.isEdit = !data.item.isEdit
        this.convertAndNotifyError(error)
      }
    },
    filterQueryUpdate () {
      this.$refs.table.refresh()
    },
    tableRefresh(){
      this.$refs.table.refresh()
    }

  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
