<template>
  <div>
    <b-card bg-variant="" class="mt-2 full-height" >
      <b-overlay
          :show="show"
          rounded="sm"
      >
        <div>
          <div >
            <ejs-schedule
                id="scheduler2"
                ref="scheduler2"
                :cellDoubleClick="false"
                :current-view="currentView"
                :dataBinding="dataBinding"
                :dateHeaderTemplate="dateHeaderTemplate"
                :eventClick="onEventClick"
                :eventDoubleClick="onEventClick"
                :eventRendered="onEventRendered"
                :eventSettings="eventSettings"
                :group="group"
                :popupOpen="openpopup"
                :quickInfoTemplates="quickInfoTemplates"
                :allowDragAndDrop="false"
                :workDays="workDays"
                :resource-header-template="resourceHeaderTemplate"
                :rowAutoHeight="true"
                :show-header-bar="true"
                :timeScale="timeScale"
                :allowTextWrap='true'
                :textWrapSettings='wrapSettings'
                :cssClass="classVal"
            >
              <e-views>
                <e-view :eventTemplate="monthTemplate" displayName="MONTH" option="TimelineMonth"/>
                <!-- <e-view :eventTemplate="weekTemplate"  displayName="DAY" option="TimelineDay"/>
                <e-view :eventTemplate="weekTemplate" displayName="WEEK" option="TimelineWeek"/>
                <e-view :eventTemplate="monthTemplate" displayName="MONTH" option="TimelineMonth"/> -->
              </e-views>
              <e-resources>
                <!-- <e-resource

                    :allow-multiple="allowMultiple"
                    :data-source="projectResourceDataSource"
                    :allowTextWrap='true'
                    color-field="color"
                    field="group_id"
                    id-field="id"
                    name="Projects"
                    text-field="text"
                    title="Choose Project"
                    id="res"
                    expandedField="IsExpand"

                /> -->
                <e-resource

                    :allow-multiple="allowMultiple"
                    :data-source="employeeDataSource"
                    color-field="color"
                    field="user_id"
                    group-i-d-field="groupId"
                    id-field="id"
                    image-field="image"
                    name="Employees"
                    text-field="text"
                    title="Employee"
                />
              </e-resources>
            </ejs-schedule>
          </div>
        </div>
      </b-overlay>

    </b-card>
  </div>
</template>

<script>
import {
  Agenda,
  DragAndDrop,
  Print,
  Resize,
  SchedulePlugin,
  TimelineMonth,
  TimelineViews,
  Week,
    Day
} from '@syncfusion/ej2-vue-schedule'
import {extend} from '@syncfusion/ej2-base'
import Vue from 'vue'
import header from './header.vue'
import quickInfoTContentmplate from './CellView/content'
import content from './CellView/content'
import quickInfoHeaderTemplate from './CellView/header'
import quickInfoFooterTemplate from './CellView/footer'
import MomentMixin from '@/mixins/MomentMixin'
import moment from 'moment'
import weekTemplate from '@/views/scheduler/eventsTemplates/weekTemplate'
import monthTemplate from './eventsTemplates/monthTemplate.vue'
import dateHeaderTemplate from '@/views/scheduler/DateHeaderTemplates/dateHeaderTemplate'

import {
  BAlert,
  BBadge,
  BButton,
  BButtonGroup,
  BCard,
  BCardBody,
  BCardText,
  BCardTitle,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BLink,
  BListGroup,
  BListGroupItem,
  BModal,
  BOverlay,
  BRow,
  VBModal,
  VBToggle,
  VBTooltip
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {mapActions, mapGetters} from 'vuex'
import shift from '@/apis/modules/shift'
import LeaveAPI from '@/apis/modules/leave_managment_admin'
import Filter from '@/mixins/FilterMixin'
import DateDiff from 'date-diff'

export default Vue.extend({
  name: 'Index',
  mixins: [MomentMixin, Filter],

  components: {
    BFormCheckbox,
    weekTemplate,
    BOverlay,
    content,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BCardTitle,
    BListGroup,
    BListGroupItem,
    BCardBody,
    BFormGroup,
    BButton,
    BModal,
    BAlert,
    BBadge,
    BButtonGroup

  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-modal': VBModal,
    'b-tooltip': VBTooltip
  },

  comments: {
    SchedulePlugin,
    extend

  },
  watch:{
    'query.value' (values) {
      this.getShiftData()
    },
  },
  data () {
    return {
      save_shifts:[],
      selectedServiceType:{
            title:'Select service type',
            value:''
      },
      wrapSettings: { wrapMode: 'Content' },
      // gridSettings: {
      //     rowHeight: 60
      // },
      showNonClinical: false,
      JObRoleFilterOption: [],
      filters: {},
      leaveDataMap: [],
      show: false,
      query: {
        value: '',
        start: null,
        end: null
      },
      popOpen: true,
      eventTemplate: false,
      modelOpen: false,
      selectedCell: {},
      timeScale: {
        enable: false,
        interval: 60,
        slotCount: 2

      },
      cssSet:'',
      classVal: "customCSS",
      dayOfWeekValue: '1',
      workDays: [1, 2, 3, 4, 5],
      resourceHeaderTemplate () {
        return {template: header}
      },
      weekTemplate () {
        return {template: weekTemplate}

      },
      monthTemplate () {
        return {template: monthTemplate}

      },
      dateHeaderTemplate () {
        return {template: dateHeaderTemplate}
      },


      quickInfoTemplates: {
        header () {
          return {template: quickInfoHeaderTemplate}
        },
        content () {
          return {template: quickInfoTContentmplate}
        },
        footer () {
          return {template: quickInfoFooterTemplate}
        }

      },
      timezoneData: [
        {
          timezone: 'America/New_York',
          text: '(UTC-05:00) Eastern Time'
        },
        {
          timezone: 'UTC',
          text: 'UTC'
        },
        {
          timezone: 'Europe/Moscow',
          text: '(UTC+03:00) Moscow+00 - Moscow'
        },
        {
          timezone: 'Asia/Kolkata',
          text: '(UTC+05:30) India Standard Time'
        },
        {
          timezone: 'Australia/Perth',
          text: '(UTC+08:00) Western Time - Pert'
        }
      ],
      projectResourceDataSource: [],
      employeeDataSource: [],
      eventSettings: {
        dataSource: '',
        cssClass: 'schedule-cell-dimension'
      },
      currentView: 'TimelineMonth',
      allowMultiple: true,
      IsExpand:'',
      group: {
        resources: ['Projects', 'Employees'],
        allowGroupEdit: false
      },
      draftLength: '',
    }
  },

  provide: {
    schedule: [Agenda, TimelineViews, TimelineMonth, Week,  Day, Resize, DragAndDrop, Print]
  },
  computed: {
    ...mapGetters(['getPracticeId'])
  },

  methods: {
    ...mapActions(['setPracticeId']),

    filterQueryUpdate () {
      this.getUserData(true)
      this.getShiftData()
    },
    monthButtonFunction(){
      this.$refs.scheduler2.changeCurrentView('TimelineMonth',0)
    },
    dataBinding (val) {
      const start_date = moment(val.query.params.find(x => x.key === 'StartDate').value).format('YYYY-MM-DD')
      const end_date = moment(val.query.params.find(x => x.key === 'EndDate').value).format('YYYY-MM-DD')
      this.query.start = start_date
      this.query.end = end_date
      this.query.value = `${start_date}_${end_date}`

    },
    onEventRendered: function (args) {
      let categoryColor = args.data.color
      if (!args.element || !categoryColor) {
        return
      }
      args.element.style.backgroundColor = categoryColor
    },
    onTimezoneDropDownChange (args) {
      this.$refs.ScheduleObj.ej2Instances.timezone = args.value
    },
    async getUserData (filtered) {
      try {
        this.show = true
        const response = await shift.userList(this.filterQuery,'salaried-staff')
        const users = response.data.data.filter(user => user.job_roles.length !== 0)
        this.projectResourceDataSource = users.reduce(function (filtered, option) {
          option.job_roles.forEach((m) => {
            var obj = {
              text: m.name,
              id: m.id,
              color: '#00C49A',
              isGroup: true,
              IsExpand:true
            }
            filtered.push(obj)
          })

          filtered = [...new Map(filtered.map(item => [item.id, item])).values()]

          return filtered
        }, [])
        if (!filtered) {
          this.JObRoleFilterOption = this.projectResourceDataSource
        }

        this.projectResourceDataSource.splice(0, 0,{
          text: '',
          id: -1,
          color: '#00C49A',
          isGroup: true,
          IsExpand:true
        })

        const salariedStaffUsers= users.map((x) => ({
          text: `${x.first_name} ${x.last_name}`,
          id: x.id,
          groupId: x.job_roles[0].id,
          image: '1',
          is_have_profile:x.photo_url?true:false,
          is_locum:false
        }))


        this.employeeDataSource = salariedStaffUsers
        this.show = false

      } catch (error) {

        this.convertAndNotifyError(error)
        this.show = false
      }

    },
    async getUserLeave () {
      try {
        const payload = {
          start: this.query.start,
          end: this.query.end,
          user_ids: this.employeeDataSource.map((x) => x.id)
        }
        if (!payload.start && !payload.end) return

        const response = await shift.getUserLeaves(payload)
        // console.log("🚀 ~ file: index.vue ~ line 375 ~ getUserLeave ~ response", response)
        this.leaveDataMap = response.data.data.map((x) => ({
          id: x.id,
          date: x.is_day?`${this.momentFormat(x.start,'DD-MMM-YY')}`+` - ` +`${ this.momentFormat(x.end,'DD-MMM-YY')}`:this.momentFormat(x.start,'DD-MMM-YY'),
          start: x.start,
          is_day: x.is_day,
          end: x.end,
          duration: x.is_day?`${new DateDiff( new Date(x.end), new Date(x.start)).days()} days`: `${new DateDiff( new Date(x.end), new Date(x.start)).hours()} hours`,
          EndTime: new Date(x.end),
          StartTime: new Date(x.start),
          starTimeForEdit: new Date(x.start),
          endTimeForEdit: new Date(x.end),
          group_id: x.user.job_roles[0].id,
          user_id: x.user.id,
          user: x.user === null? 'N/A':x.user.first_name +' '+ x.user.last_name,
          reason: x.sickness_type_id && x.leave_reason_id == 1 ? x.sickness_type.name : x.leave_reason_id ? x.leave_reason.name : 'N/A',
          reason_id: x.leave_reason_id === null ? 'N/A' : x.leave_reason.id,
          sickness_id: x.sickness_type_id === null ? 'N/A' : x.sickness_type.id,
          job_role: x.user.job_roles,
          Subject: 'Leave',
          approved_by: x.administrator === null ? 'N/A' : `${x.administrator.first_name}  ${x.administrator.last_name}`,
          state: x.state,
          status: x.state.charAt(0).toUpperCase() + x.state.substring(1),
          time:x.is_day?'Full day': `${this.momentFormat(x.start,'HH:mm')}` + ' - ' + `${this.momentFormat(x.end,'HH:mm')}`
        }))
      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },
    async getShiftData () {
      if (!this.query.start || !this.query.end) return
      try {
        this.show = true
        let calenderData = await shift.getCalenderData(this.query.start, this.query.end,this.selectedServiceType.value)
        let withAssignee = calenderData.data.data.filter(function (obj) {
          return obj.assignee !== null
        })
        await this.getUserLeave()

        let sheduleDataMap = this.leaveDataMap

        let mappedStates = sheduleDataMap.map((level, i) => {
          switch (level.state) {
          case 'draft':
            // level.color ='#605d5d'
            level.color = '#B2B2B2'
            level.border = "black"
            //level.width = '100px'
            break
          case 'cancel':
            // level.color ='#605d5d'
            level.color = '#B2B2B2'
            level.border = "black"
            //level.width = '100px'
            break
          case 'leave':
            // level.color ='#605d5d'
            level.color = '#ffffff'
            break
          case 'approved':

            level.color = '#1B9AAA'
            break
          }
          // // ...
          return level
        })

        const sheduleDataFinalArray = mappedStates.filter((m) =>m.is_pcn !== true || m.state !== 'draft')
        this.eventSettings = {dataSource: sheduleDataFinalArray}
        this.draftLength = sheduleDataFinalArray.filter(m => m.state === 'draft').length
        this.save_shifts = await sheduleDataFinalArray
        await this.getServices(sheduleDataFinalArray)

        this.show = false
      } catch (error) {
        this.convertAndNotifyError(error)
        this.show = false
      }
    },
    async onEventClick (agrs) {
      if (agrs.event.Subject === 'leave' ) {
        agrs.cancel = true
      } else {
        // console.log('Hi')
        // this.selectedCell = await agrs
      }
    },
    openpopup (agrs) {
      if (!agrs.data.Guid) {
        agrs.cancel = true
        this.selectedCell = agrs
        if (agrs.data.group_id === -1){
          return
        }else {
          return
        }

      }
    },
  },
  async mounted () {
    this.$refs.scheduler2.ej2Instances.firstDayOfWeek = 1
    this.cssSet = true
    await this.getUserData()
  }
})

</script>
<style lang="scss">
.e-today{
  display: none !important;      // To hide Today option
}
// .full-height {
//   height: 100% !important;
// }
// .e-schedule .e-timeline-view .e-resource-group-cells {
//   background-color: #CCE6F4;
//   height: 30px!important;
// }


.full-height {
  height: 100% !important;
}
.test .e-schedule .e-timeline-view .e-resource-tree-icon {
  float: right;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-top: 3px;
}

.employee-name {
  padding-bottom: 10px;
  padding-top: 10px;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

#scheduler2 {
  min-height: 280px;
  max-height: 300px;
}

@media (#scheduler2 min-height: 300px) and (#scheduler2 max-height: 600px) {
  .e-quick-popup-wrapper {

    bottom: 0px !important;
    top: auto !important;

  }
}

.e-schedule .e-timeline-month-view .e-appointment {
  color: #fff;
  overflow: hidden;
  position: absolute;
  // height: 15px !important;
  border-radius: 0px;
  margin-top: 5px;
  // width: 80px !important;

}

::v-deep .e-schedule .e-month-view .e-appointment{
  height: 4px !important;
}

// .e-appointment {
//     width: 72px !important;
// }

// .e-schedule .e-timeline-view .e-appointment .e-appointment-details {
//   padding: 0px !important;
//   width: 100%;
// }

.employee-image {
  background-color: rgba(27, 154, 170, 0.12) !important;
  margin: 5px;
  padding: 5px;
}

</style>
