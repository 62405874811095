import { render, staticRenderFns } from "./footer.vue?vue&type=template&id=595f9794&scoped=true&"
import script from "./footer.vue?vue&type=script&lang=js&"
export * from "./footer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "595f9794",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\10QBIT\\Hyre\\hyre_mp_fe\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('595f9794')) {
      api.createRecord('595f9794', component.options)
    } else {
      api.reload('595f9794', component.options)
    }
    module.hot.accept("./footer.vue?vue&type=template&id=595f9794&scoped=true&", function () {
      api.rerender('595f9794', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/admin-leave/views/dashboard-views/scheduler/CellView/footer.vue"
export default component.exports