var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-sidebar", {
    attrs: {
      id: "user-profile-sidebar",
      visible: _vm.isProfileSidebar,
      backdrop: "",
      "bg-variant": "white",
      "no-header": "",
      right: "",
      width: "450px",
      shadow: "",
      "sidebar-class": "sidebar-lg"
    },
    on: {
      change: function(val) {
        return _vm.$emit("update:is-profile-sidebar", val)
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var hide = ref.hide
          return [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
              },
              [
                _c("h4", { staticClass: "mb-0" }),
                _c("feather-icon", {
                  staticClass: "ml-1 cursor-pointer",
                  attrs: { icon: "XIcon", size: "16" },
                  on: { click: hide }
                })
              ],
              1
            ),
            _c(
              "validation-observer",
              { ref: "refFormObserver" },
              [
                _c(
                  "b-card",
                  [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-12 d-flex justify-content-center" },
                        [
                          _c("b-avatar", {
                            staticClass: "badge-minimal mt-n2",
                            attrs: {
                              size: "130px",
                              variant: "light-primary",
                              badge: "",
                              "badge-variant": "success",
                              src: _vm.getUserImage(_vm.userDetails.user_id)
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "col-12 d-flex justify-content-center mt-1"
                      },
                      [
                        _c("h2", { staticClass: " font-weight-700 " }, [
                          _vm._v(_vm._s(_vm.userDetails.name))
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12 d-flex justify-content-center" },
                      [
                        _c(
                          "b-alert",
                          { attrs: { variant: "primary", show: "" } },
                          [
                            _c(
                              "small",
                              {
                                staticClass: "text-dark font-weight-700 mx-1 "
                              },
                              [_vm._v(_vm._s(_vm.userDetails.job_role) + " ")]
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-12 d-flex justify-content-start mt-1"
                      },
                      [
                        _c("h5", { staticClass: "mb-0 font-weight-700" }, [
                          _vm._v("Personal Info")
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12 d-flex justify-content-start" },
                      [
                        _c("div", { staticClass: "p-2 ml-n2" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 d-flex justify-content-start "
                            },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "PhoneIcon" }
                              }),
                              _c("div", { staticClass: "ml-1" }, [
                                _vm._v(_vm._s(_vm.userDetails.telephone_no))
                              ])
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 d-flex justify-content-start mt-1"
                            },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "MailIcon" }
                              }),
                              _c("div", { staticClass: "ml-1" }, [
                                _vm._v(" " + _vm._s(_vm.userDetails.email))
                              ])
                            ],
                            1
                          )
                        ])
                      ]
                    ),
                    _c("div", { staticClass: "mt-n1" }, [_c("hr")]),
                    _c(
                      "div",
                      {
                        staticClass: "col-12 d-flex justify-content-start mt-2"
                      },
                      [
                        _c("h5", { staticClass: "mb-0 font-weight-700" }, [
                          _vm._v("Professional Info")
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12 d-flex justify-content-start" },
                      [
                        _c("div", { staticClass: "p-2 ml-n2" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 d-flex justify-content-start "
                            },
                            [_vm._v(" Qualification ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 d-flex justify-content-start mt-1"
                            },
                            [_vm._v(" Speciality ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 d-flex justify-content-start mt-1"
                            },
                            [_vm._v(" IT System ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 d-flex justify-content-start mt-1"
                            },
                            [_vm._v(" Smart Card Number ")]
                          )
                        ]),
                        _c("div", { staticClass: "p-2 " }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 d-flex justify-content-start",
                              staticStyle: { "margin-top": "6px" }
                            },
                            [
                              _c(
                                "h6",
                                { staticClass: "mb-0 font-weight-700" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.userDetails.qualification) +
                                      " "
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 d-flex justify-content-start mt-1",
                              staticStyle: { "margin-top": "4px" }
                            },
                            [
                              _c(
                                "h6",
                                { staticClass: "mb-0 font-weight-700" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.userDetails.speciality) +
                                      " "
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 d-flex justify-content-start mt-1"
                            },
                            [
                              _c(
                                "h6",
                                { staticClass: "mb-0 font-weight-700" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.userDetails.it_system) +
                                      " "
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 d-flex justify-content-start mt-1"
                            },
                            [
                              _c(
                                "h6",
                                { staticClass: "mb-0 font-weight-700" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.userDetails.smart_card_number
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ]
                          )
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-12 d-flex justify-content-start mt-n2"
                      },
                      [
                        _c("div", { staticClass: "p-2 ml-n2" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 d-flex justify-content-start "
                            },
                            [_vm._v(" Skills ")]
                          )
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-12 d-flex justify-content-start mt-n2"
                      },
                      [
                        _c("div", { staticClass: "p-2 ml-n2" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-12 d-flex justify-content-start "
                            },
                            [
                              _c(
                                "b-row",
                                {},
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "font-weight-light",
                                      attrs: { cols: "12" }
                                    },
                                    _vm._l(_vm.skills, function(skill) {
                                      return _c(
                                        "b-badge",
                                        {
                                          key: skill,
                                          staticClass: "mr-1",
                                          attrs: { variant: "light-info" }
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "text-primary" },
                                            [_vm._v(_vm._s(skill))]
                                          )
                                        ]
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      ]
                    ),
                    _c("div", { staticClass: "mt-n1" }, [_c("hr")]),
                    _c(
                      "div",
                      {
                        staticClass: "col-12 d-flex justify-content-start mt-2"
                      },
                      [
                        _c("h5", { staticClass: "mb-0 font-weight-700" }, [
                          _vm._v(" Unavailability Today")
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12 d-flex justify-content-start" },
                      [
                        _c(
                          "div",
                          { staticClass: "p-2 ml-n2" },
                          _vm._l(_vm.unavailabilityTimes, function(time) {
                            return _c(
                              "div",
                              {
                                key: time,
                                staticClass:
                                  "col-12 d-flex justify-content-start mb-1"
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "b-avatar",
                                      {
                                        attrs: {
                                          variant: "success",
                                          rounded: "",
                                          size: "10"
                                        }
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: {
                                            icon: "CheckIcon",
                                            size: "1"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c("span", { staticClass: "ml-1" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.momentFormat(time.start, "HH:mm")
                                      ) +
                                      " - " +
                                      _vm._s(
                                        _vm.momentFormat(time.end, "HH:mm")
                                      )
                                  )
                                ])
                              ]
                            )
                          }),
                          0
                        )
                      ]
                    ),
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "ripple",
                            rawName: "v-ripple.400",
                            value: "rgba(255, 255, 255, 0.15)",
                            expression: "'rgba(255, 255, 255, 0.15)'",
                            modifiers: { "400": true }
                          }
                        ],
                        attrs: { variant: "primary", block: "" },
                        on: {
                          click: function($event) {
                            return _vm.goToProfile()
                          }
                        }
                      },
                      [_vm._v(" View Full Profile ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }