var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.selectedCell
      ? _c("div", { staticClass: "p-1" }, [
          _c(
            "div",
            [
              _vm.selectedCell.service
                ? _c(
                    "b-row",
                    [
                      _vm.selectedCell.Subject !== "."
                        ? _c("div", { staticClass: "font-weight-bold mr-1 " }, [
                            _vm._v(_vm._s(_vm.selectedCell.Subject))
                          ])
                        : _vm._e(),
                      _vm.selectedCell.is_covid_shift
                        ? _c(
                            "b-badge",
                            {
                              staticClass: "mr-1",
                              attrs: { size: "sm", variant: "danger" }
                            },
                            [_vm._v(" COVID-19 ")]
                          )
                        : _vm._e(),
                      _vm.selectedCell.service.type === "clinical"
                        ? _c(
                            "b-badge",
                            {
                              staticClass: "text-capitalize",
                              attrs: { size: "sm", variant: "light-success" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.selectedCell.service.type) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.selectedCell.service.type === "non-clinical"
                        ? _c(
                            "b-badge",
                            {
                              staticClass: "text-capitalize",
                              attrs: { size: "sm", variant: "light-info" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.selectedCell.service.type) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _c("b-row", [
                _vm.selectedCell.shift_type
                  ? _c("div", { staticClass: "font-weight-bold mr-1  " }, [
                      _vm._v(_vm._s(_vm.selectedCell.shift_type.name))
                    ])
                  : _vm._e()
              ]),
              _vm.selectedCell
                ? _c(
                    "b-row",
                    { staticClass: "mt-1" },
                    [
                      _c("feather-icon", {
                        staticClass: "mr-1",
                        attrs: { icon: "CoffeeIcon" }
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.momentFormat(
                              _vm.selectedCell.StartTime,
                              "ddd DD MMMM YYYY"
                            )
                          ) +
                          " "
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectedCell
                ? _c(
                    "b-row",
                    { staticClass: "mt-1" },
                    [
                      _c("feather-icon", {
                        staticClass: "mr-1",
                        attrs: { icon: "ClockIcon" }
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.momentFormat(
                              _vm.selectedCell.StartTime,
                              "HH:mm"
                            )
                          ) +
                          " - " +
                          _vm._s(
                            _vm.momentFormat(_vm.selectedCell.EndTime, "HH:mm")
                          ) +
                          " "
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectedCell
                ? _c(
                    "b-row",
                    { staticClass: "mt-1" },
                    [
                      _c("feather-icon", {
                        staticClass: "mr-1",
                        attrs: { icon: "HelpCircleIcon" }
                      }),
                      _vm._v(" " + _vm._s(_vm.selectedCell.reason) + " ")
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }