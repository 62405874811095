<template>
    <div class="dropdown float-right">
      <b-dropdown
          no-caret
          right
          toggle-class="p-0 mr-1"
          variant="link"
      >
        <template #button-content>
          <feather-icon
              class="align-middle text-body"
              icon="MoreVerticalIcon"
              size="16"
          />
        </template>
        <b-dropdown-item
           @click="isProfileSidebar = true"
        >
          <feather-icon
              class="mr-50"
              icon="UserIcon"
              size="16"
          />
          <span class="font-weight-bolder"> View profile </span>
        </b-dropdown-item>

      </b-dropdown>

      <ProfileSidebar
          ref="UserProfileSidebar"
          :is-profile-sidebar.sync="isProfileSidebar"
          :user-details="userData"
          :unavailability-times="unavailabilityTimes"
          @toggleSideBars="toggleSideBars()"
      />

    </div>

</template>

<script>

import ProfileSidebar from '../sidebars/profileSidebar'

import {BDropdown, BDropdownItem} from 'bootstrap-vue'

export default {
  components: {
    BDropdown, BDropdownItem, ProfileSidebar,
  },
props:{
  userData:{
    type: Object
  }
},
data() {
  return {
    isProfileSidebar: false,
    isChatSidebar: false,
    unavailabilityTimes:[],
  }
},
watch:{
  isProfileSidebar(val){
    if(val){
      this.getUnavailability()
    }
  }
},
methods: {
  toggleSideBars() {
    this.isProfileSidebar = !this.isProfileSidebar
  },
  toggleChatSideBars() {
    this.isChatSidebar = !this.isChatSidebar
  },
  getUnavailability(){
      const today = new Date();
      const day = today.getDay()
      let todayTimes = []
      console.log(this.userData.unavailabilities)
      this.userData.unavailabilities.forEach(function(key){
        if(key.day_number == day){
            todayTimes.push(key)
        }
      })
      this.unavailabilityTimes = todayTimes
    },
},
mounted(){
}

}
</script>

<style scoped>

</style>
