<template>
  <div>
    <div className="template-wrap">
      <div class="employee-category" v-bind:class="{ active: isGroup(), }">
        <div>

          <img
          @click="$router.push(`/staff-bank/${uid}`)"
          v-if="!isGroup() && !isVacant() "
          class="employee-image"
          :src="setImage()"
          alt=""
          >

        </div>
        <div class="employee_name  flex" style="padding-bottom: 1px; padding-top: 2px!important;">

         <div class="" v-bind:class="{ employee: !isGroup()}"> {{ getEmployeeName().slice(0, 25) + (getEmployeeName().length > 25 ? "..." : "") }} </div>
          <span v-if="isLocum()" style="padding:8px; width: 20px; background: #CCE6F4; border-radius: 8px;  font-weight: bold" class=" text-dark font-weight-700"> L  </span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {BAvatar, BButton,  BBadge,} from 'bootstrap-vue'

export default {
  name: 'Header',
  data() {
    return {
      data: {},
      uid:''
    }
  },
  component:{
    BAvatar,
    BButton,
    BBadge

  },
  computed: {

  },

  mounted() {

  },
  methods: {
    isLocum(){
      const value = JSON.parse(JSON.stringify(this.data))
      return value.resourceData.is_locum
    },
    getEmployeeName() {
      const value = JSON.parse(JSON.stringify(this.data))
      return (value.resourceData) ? value.resourceData[value.resource.textField] : value.resourceName
    },
    getEmployeeImage() {
      const value = JSON.parse(JSON.stringify(this.data))
      return value.resourceData.image
      //  return (`../../assets/images/avatars/${value.resourceData.image}`)
    },
    isGroup() {
      const value = JSON.parse(JSON.stringify(this.data))
      return value.resourceData.isGroup
    },
    isVacant(){
      const value = JSON.parse(JSON.stringify(this.data))

      return value.resourceData.groupId === -1
    },

    setImage(){
      const value = JSON.parse(JSON.stringify(this.data))
      this.uid = value.resourceData.id
      if (value.resourceData.is_have_profile){
        const image = this.getUserImage(value.resourceData.id, true)
        return image
      }else {
        // return '../../assets/images/avatars/1.png'
        return require('@/assets/images/avatars/1.png')
      }
    },
  },
}
</script>

<style>
    .schedule-vue-sample .e-schedule:not(.e-device) .e-agenda-view .e-content-wrap table td:first-child {
        width: 90px;
    }

    .schedule-vue-sample .e-schedule .e-agenda-view .e-resource-column {
        width: 100px;
    }
    .schedule-vue-sample .block-events.e-schedule .template-wrap {
        width: 100%;
    }

    .schedule-vue-sample .block-events.e-schedule .e-vertical-view .e-resource-cells {
        height: 58px;
    }

    .schedule-vue-sample .block-events.e-schedule .e-timeline-view .e-resource-left-td,
    .schedule-vue-sample .block-events.e-schedule .e-timeline-month-view .e-resource-left-td {
        width: 170px;
    }

    .schedule-vue-sample .block-events.e-schedule .e-resource-cells.e-child-node .employee-category,
    .schedule-vue-sample .block-events.e-schedule .e-resource-cells.e-child-node .employee-name {
        padding: 1px
    }

    .employee-image {
        width: 45px;
        height: 40px;
        float: left;
        border-radius: 50%;
        margin-right: 10px;
    }

    .employee-image:hover{
      cursor: pointer;
    }
    [dir] .e-schedule .e-timeline-view .e-resource-tree-icon {
      padding-bottom: 0px!important;
      padding-top: 0!important;
      margin-top: 0!important;
    }
    .employee_name {
      padding-top: 1px!important;
      padding-bottom: 1px!important;
        /*font-size: 11px;*/
    }

    .employee {
      padding-top: 10px!important;
      padding-bottom: 10px!important;
        margin-top: 4px;
    }

    .employee-designation {
        /*font-size: 10px;*/
    }
    .e-schedule .e-timeline-view .e-resource-cells.e-parent-node{
      /*font-size: 10px;*/
      height: 30px!important;
      padding-top: 0!important;
      background-color: #CCE6F4;
    }

    [dir] .e-schedule .e-timeline-view .e-resource-tree-icon {
            padding-bottom: 0px;
            padding-top: 0px;
            margin-top: 0px;
         }


</style>
