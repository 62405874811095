var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "mt-2 full-height", attrs: { "bg-variant": "" } },
        [
          _c("b-overlay", { attrs: { show: _vm.show, rounded: "sm" } }, [
            _c("div", [
              _c(
                "div",
                [
                  _c(
                    "ejs-schedule",
                    {
                      ref: "scheduler2",
                      attrs: {
                        id: "scheduler2",
                        cellDoubleClick: false,
                        "current-view": _vm.currentView,
                        dataBinding: _vm.dataBinding,
                        dateHeaderTemplate: _vm.dateHeaderTemplate,
                        eventClick: _vm.onEventClick,
                        eventDoubleClick: _vm.onEventClick,
                        eventRendered: _vm.onEventRendered,
                        eventSettings: _vm.eventSettings,
                        group: _vm.group,
                        popupOpen: _vm.openpopup,
                        quickInfoTemplates: _vm.quickInfoTemplates,
                        allowDragAndDrop: false,
                        workDays: _vm.workDays,
                        "resource-header-template": _vm.resourceHeaderTemplate,
                        rowAutoHeight: true,
                        "show-header-bar": true,
                        timeScale: _vm.timeScale,
                        allowTextWrap: true,
                        textWrapSettings: _vm.wrapSettings,
                        cssClass: _vm.classVal
                      }
                    },
                    [
                      _c(
                        "e-views",
                        [
                          _c("e-view", {
                            attrs: {
                              eventTemplate: _vm.monthTemplate,
                              displayName: "MONTH",
                              option: "TimelineMonth"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "e-resources",
                        [
                          _c("e-resource", {
                            attrs: {
                              "allow-multiple": _vm.allowMultiple,
                              "data-source": _vm.employeeDataSource,
                              "color-field": "color",
                              field: "user_id",
                              "group-i-d-field": "groupId",
                              "id-field": "id",
                              "image-field": "image",
                              name: "Employees",
                              "text-field": "text",
                              title: "Employee"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }