<template>
  <b-sidebar
      id="manage-leave-sidebar-sidebar"
      :visible="isManageLeaveRequestSidebarActive"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
      @change="
      (val) => $emit('update:is-manage-leave-request-sidebar-active', val)
    "
      @hidden="tabIndex = 0"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
          class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h4 class="mb-0">Manage Leave Requests</h4>

        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="cancel(); hide()"
        />
      </div>

      <!-- BODY -->
      <validation-observer ref="refFormObserver">
        <b-overlay :show="formLoading" rounded="sm">
          <!-- Form -->
          <div class="p-2">
            <b-tabs v-model="tabIndex" lazy>
              <b-tab title="General">
                <b-row class="mb-1">
                  <b-col cols="12" md="5">
                    <h5>Leave year start datesss</h5>
                    <validation-provider
                        #default="{ errors }"
                        name="Start Date"
                        rules="required"
                    >
                      <b-form-group>
                        <b-input-group class="input-group-merge">
                          <!-- <b-input-group-prepend is-text>
                    <feather-icon icon="CalendarIcon"/>
                  </b-input-group-prepend> -->
                          <b-form-datepicker
                              id="date"
                              v-model="date"
                              :date-format-options="{
                              year: '2-digit',
                              month: '2-digit',
                              day: '2-digit',
                            }"
                              :disabled="isStartDateDisabled"
                              class="form-control"
                              locale="en-UK"
                              placeholder="Select"
                              start-weekday="1"
                              trim
                          />
                          <!-- <flat-pickr
                      v-model="date"
                      :disabled="isStartDateDisabled"
                      v-bind:class="{pickrstyleActive:isStartDateDisabled, pickrstyleDective: isStartDatEnabled}"
                      :config="{ enableTime: false, noCalendar: false,  dateFormat: 'Y-m-d', locale: {
                        firstDayOfWeek: 1
                    }}"
                      class="form-control"
                      placeholder="Select"
                  /> -->
                        </b-input-group>
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>

                  <b-col>
                    <b-button
                        v-if="isStartDateDisabled === true"
                        class="
                        btn-icon
                        shadow-sm
                        bg-white
                        mt-2
                        text-primary
                        shado
                      "
                        size="20"
                        variant="outline-white"
                        @click="changeStartDateDisabledState()"
                    >
                      <feather-icon
                          icon="EditIcon"
                          size="18"
                          style="color: #168b9a"
                      />
                    </b-button>
                  </b-col>
                </b-row>
                <!-- <b-row class="mb-1">
                  <b-col cols="12" md="12" class="mt-1">
                    <h5>Do you want to allow unlimited leaves?</h5>
                    <b-row>
                      <b-col md="2">
                        <b-form-radio
                          v-model="allowUnlimitedLeave"
                          :value="true"
                        >
                          Yes
                        </b-form-radio>
                      </b-col>
                      <b-col md="2">
                        <b-form-radio
                          v-model="allowUnlimitedLeave"
                          :value="false"
                        >
                          No
                        </b-form-radio>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row> -->
                <b-row class="mb-1">
                  <b-col class="mt-1" cols="12" md="12">
                    <h5>How do you want to track leave?</h5>
                    <b-row>
                      <b-col md="2">
                        <b-form-radio
                            v-model="isTrackLeaveOnDays"
                            :value="true"

                        >
                          Days
                        </b-form-radio>
                      </b-col>
                      <b-col md="2">
                        <b-form-radio
                            v-model="isTrackLeaveOnDays"
                            :value="false"
                        >
                          Hours
                        </b-form-radio>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <!-- <b-row class="mb-1">
            <b-col cols="12" md="12" class="mt-1">
            <h5>Allow users to carry out remaining leaves?</h5>
            <b-row>
              <b-col md="2">
                <b-form-radio
                      v-model="allowCarryOutLeave"
                      :value="true"
                    >
                        Yes
                    </b-form-radio>
              </b-col>
              <b-col md="2">
                <b-form-radio
                      v-model="allowCarryOutLeave"
                      :value="false"
                    >
                        No
                    </b-form-radio>
              </b-col>
            </b-row>
            </b-col>
          </b-row> -->
                <b-row class="">
                  <b-col class="mt-1" cols="12">
                    <h5>How many leave days can be taken per year?</h5>
                    <label
                    >You can change this number for each staff member on their
                      Leave History page</label
                    >
                  </b-col>
                </b-row>
                <b-row v-if="isTrackLeaveOnDays" class="mb-1">
                  <!-- <b-row class="mb-1"> -->
                  <b-col cols="12" md="3">
                    <b-form-radio-group>
                      <b-form-input
                          v-model="noOfLeaves"
                          type="number"
                      ></b-form-input>

                    </b-form-radio-group>
                  </b-col>
                  <b-col class="d-flex" cols="12" md="9">
                    <p style="margin-top: 10px">
                      days
                    </p>
                    <p class="ml-2 text-primary" style="margin-top: 10px">
                      This equates to {{ (noOfLeaves * workingHours).toFixed(2) }} hours
                    </p>
                  </b-col>
                </b-row>
                <b-row v-else class="mb-1">
                  <b-col cols="12" md="3">
                    <b-form-radio-group>
                      <b-form-input
                          v-model="noOfLeaves"
                          min="0"
                          oninput="validity.valid||(value='');"
                          step="any"
                          type="number"
                      ></b-form-input>
                    </b-form-radio-group>
                  </b-col>
                  <b-col class="d-flex" cols="12" md="9">
                    <p style="margin-top: 10px">hours</p>
                    <p class="ml-2 text-primary" style="margin-top: 10px">
                      This equates to {{ (noOfLeaves / workingHours).toFixed(2) }} days
                    </p>
                  </b-col>
                </b-row>
                <b-row class="mb-1">
                  <b-col class="mt-1" cols="12" md="12">
                    <h5>The leave entitlement includes bank holidays?</h5>
                    <b-row>
                      <b-col md="2">
                        <b-form-radio
                            v-model="isIncludeBankHolidays"
                            :value="true"
                        >
                          Yes
                        </b-form-radio>
                      </b-col>
                      <b-col md="2">
                        <b-form-radio
                            v-model="isIncludeBankHolidays"
                            :value="false"
                        >
                          No
                        </b-form-radio>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row class="">
                  <b-col class="mt-1" cols="12">
                    <h5>Working hours per day</h5>
                    <label
                    >No of hours in a working day (for the NHS this is
                      typically 7.5 hours)</label
                    >
                  </b-col>
                </b-row>
                <b-row class="">
                  <b-col class="mb-1" cols="12" md="4">
                    <b-form-radio-group>
                      <b-form-input
                          v-model="workingHours"
                          min="0"
                          oninput="validity.valid||(value='');"
                          step="any"
                          type="number"
                      ></b-form-input>
                    </b-form-radio-group>
                  </b-col>
                  <b-col v-if="errorLeaveCountPerYear" cols="12" md="12">
                    <h5 class="text-danger">Can't less leave days can be taken per year than used leave</h5>
                  </b-col>
                </b-row>

              </b-tab>

              <b-tab title="Maximum leave">
                <b-row class="mb-1">
                  <b-col class="mt-1" cols="12">
                    <h5>Maximum staff on leave per day</h5>
                    <div class="d-flex">
                      <div class="mr-2">
                        <label>Add maximum leave limit for all roles</label>
                      </div>
                      <div class="d-flex">
                        <div class="mr-1">
                          <b-form-radio
                              v-model="allowMaxLeaveForAll"
                              :value="true"
                          >
                            Yes
                          </b-form-radio>
                        </div>
                        <div>
                          <b-form-radio
                              v-model="allowMaxLeaveForAll"
                              :value="false"
                          >
                            No
                          </b-form-radio>
                        </div>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row v-if="allowMaxLeaveForAll">
                  <b-col cols="12" md="4">
                    <div class="ml-0 set-mb pt-1">
                      <h6>All roles</h6>
                    </div>
                  </b-col>
                  <b-col class="mb-1" cols="12" md="2">
                    <b-form-radio-group>
                      <b-form-input
                          v-model="maxLeaveForAll"
                          min="0"
                          oninput="validity.valid||(value='');"
                          step="any"
                          type="number"
                      ></b-form-input>
                    </b-form-radio-group>
                  </b-col>
                </b-row>
                <b-row
                    v-for="(role, index) in assignedJobRoles"
                    v-else
                    :id="role.id"
                    :key="role.id"
                    ref="rowJobRole"
                >
                  <b-col cols="12" md="4">
                    <div class="ml-0 set-mb pt-1">
                      <!-- <div class="set-mb-head d-md-none">ROLE</div> -->
                      <h6>{{ role.role }}</h6>
                    </div>
                  </b-col>
                  <b-col class="mb-1" cols="12" md="2">
                    <b-form-radio-group>
                      <b-form-input
                          v-model="role.max_count"
                          min="0"
                          oninput="validity.valid||(value='');"
                          step="any"
                          type="number"
                      ></b-form-input>
                    </b-form-radio-group>
                  </b-col>
                  <b-col class="mb-1" cols="12" md="2">
                    <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        class="btn-icon rounded-circle"
                        size="20"
                        variant="outline-white"
                        @click="removeRoleItem(index)"
                    >
                      <feather-icon icon="MinusCircleIcon" size="17"/>
                    </b-button>
                  </b-col>
                </b-row>
                <b-row v-if="!allowMaxLeaveForAll">
                  <!-- Add Button -->
                  <b-col lg="7" md="3">
                    <div class="set-mb">
                      <b-dropdown
                          id="dropdown-1"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          drop
                          plain
                          style="background-color: rgba(255, 225, 225, 0.1)"
                          text="+ Add Role"
                          variant=" bg-white text-primary"
                      >
                        <b-form-group class="mx-1">
                          <b-input-group class="input-group-merge">
                            <b-input-group-prepend is-text>
                              <feather-icon icon="SearchIcon"/>
                            </b-input-group-prepend>
                            <b-form-input
                                id="icons-search"
                                v-model="filters['name']"
                                placeholder="Search"
                            />
                          </b-input-group>
                        </b-form-group>

                        <div
                            class="ml-1 mr-1 scrollable-menu"
                            @click="repeateAgainJobRole"
                        >
                          <b-dropdown-item
                              v-for="option in rolesOption"
                              :key="option.id"
                              :value="option.name"
                              @click="selectedRole = option"
                          >
                            {{ option.role }}
                          </b-dropdown-item>
                        </div>
                      </b-dropdown>
                    </div>
                  </b-col>
                </b-row>
              </b-tab>

              <b-tab title="Carry forward">
                <b-row class="">
                  <b-col class="mt-1" cols="12">
                    <h5>Carry forward</h5>
                    <label
                    >When you get to year end, how many unused annual leave
                      days <br/>
                      can staff carry forward into the next year?</label
                    >
                  </b-col>
                </b-row>
                <b-row class="mb-1">
                  <b-col cols="12" md="4">
                    <b-form-radio-group>
                      <b-form-input
                          v-model="carryForwardCount"
                          min="0"
                          oninput="validity.valid||(value='');"
                          step="any"
                          type="number"
                      ></b-form-input>
                    </b-form-radio-group>
                  </b-col>
                </b-row>
                <b-row class="">
                  <b-col class="mb-1" md="9">
                    <label class="mr-1"
                    >The days will be automatically carried forward on </label
                    ><strong>{{ carryEndDate }}</strong>
                  </b-col>
                </b-row>
              </b-tab>

              <b-tab title="Locked dates">
                <b-row class="">
                  <b-col class="mt-1" cols="12">
                    <h5>Locked dates</h5>
                    <label>Set dates when leave cannot be taken</label>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form
                        ref="form"
                        class="repeater-form"
                        @submit.prevent="repeateAgain"
                    >
                      <b-row
                          v-for="(item, index) in leaveItems"
                          :id="item.id"
                          :key="item.id"
                          ref="row"
                      >

                        <b-col cols="12" md="3">
                          <label>Start Date</label>

                          <validation-provider
                              #default="{ errors }"
                              name="Start Date"

                          >
                            <b-form-group>
                              <b-input-group class="input-group-merge">
                                <b-form-datepicker
                                    v-model="leaveItems[index].startTime"
                                    :date-format-options="{
                                        year: '2-digit',
                                        month: '2-digit',
                                        day: '2-digit',
                                      }"
                                    :max="leaveItems[index].endTime"
                                    :min="min"
                                    class="form-control"
                                    locale="en-UK"
                                    placeholder="Select"
                                    start-weekday="1"
                                    trim
                                />
                              </b-input-group>
                            </b-form-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>

                        <b-col cols="12" md="3">
                          <label>End Date</label>
                          <validation-provider
                              #default="{ errors }"
                              name="End Date"

                          >
                            <!-- rules="required" -->
                            <b-form-group>
                              <b-input-group class="input-group-merge">
                                <b-form-datepicker
                                    v-model="leaveItems[index].endTime"
                                    :date-format-options="{
                                        year: '2-digit',
                                        month: '2-digit',
                                        day: '2-digit',
                                      }"
                                    :min="leaveItems[index].startTime"
                                    class="form-control"
                                    locale="en-UK"
                                    placeholder="Select"
                                    start-weekday="1"
                                    trim
                                />
                              </b-input-group>
                            </b-form-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>
                        <b-col cols="12" md="3">
                          <label>Reason</label>
                          <validation-provider
                              #default="{ errors }"
                              name="Reason"
                          >
                            <b-form-group>
                              <b-input-group class="input-group-merge">
                                <b-form-input
                                    v-model="leaveItems[index].reason"
                                    type="text"
                                ></b-form-input>
                              </b-input-group>
                            </b-form-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>
                        <b-col
                            class=""
                            cols="12"
                            md="3"
                            style="margin-top: 8px"
                        >
                          <b-button
                              v-if="leaveItems.length == index + 1"
                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              class="btn-icon rounded-circle mt-1"
                              size="20"
                              variant="outline-white"
                              @click="repeateAgain"
                          >
                            <feather-icon icon="PlusCircleIcon" size="17"/>
                          </b-button>

                          <b-button
                              v-if="leaveItems.length ==  1"
                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              class="btn-icon rounded-circle mt-1"
                              size="20"
                              variant="outline-white"
                              @click="removeItem1(index)"

                          >
                            <feather-icon icon="MinusCircleIcon" size="17"/>
                          </b-button>

                          <b-button
                              v-if="leaveItems.length > 1"
                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              class="btn-icon rounded-circle mt-1"
                              size="20"
                              variant="outline-white"
                              @click="removeItem(index)"
                          >
                            <feather-icon icon="MinusCircleIcon" size="17"/>
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-form>
                  </b-col>
                </b-row>
              </b-tab>
            </b-tabs>
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  :disabled="errorLeaveCountPerYear"
                  type="submit"
                  variant="primary"
                  @click="manageLeave()"
              >
                Save
              </b-button>
              <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  class="text-primary ml-1 shadow"
                  type="button"
                  variant="outline-white"
                  @click="cancel(); hide()"
              >
                Cancel
              </b-button>
            </div>
          </div>
        </b-overlay>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BAlert,
  BBadge,
  BButton,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadio,
  BFormRadioGroup,
  BFormTextarea,
  BInputGroup,
  BInputGroupPrepend,
  BListGroup,
  BListGroupItem,
  BOverlay,
  BRow,
  BSidebar,
  BTab,
  BTable,
  BTabs
} from 'bootstrap-vue'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {alphaNum, required} from '@validations'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import moment from 'moment'
import AdminLeaveAPI from '@/apis/modules/leave_managment_admin'
import leaveManagementAdmin from '@/apis/modules/leave_managment_admin'
import ErrorMessages from '@/mixins/ErrorMessages'
import MomentMixin from '@/mixins/MomentMixin'
import Filter from '@/mixins/FilterMixin'

export default {
  mixins: [MomentMixin, ErrorMessages, Filter],

  components: {
    flatPickr,
    vSelect,
    BAlert,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,
    BFormTextarea,
    BButton,
    BFormDatepicker,
    BFormCheckbox,
    BRow,
    BCol,
    BTab,
    BTabs,
    BInputGroupPrepend,
    BTable,
    BOverlay,
    BBadge,
    ValidationProvider,
    ValidationObserver,
    BFormRadio,
    BFormRadioGroup,
    BDropdown,
    BDropdownItem,
    BListGroup,
    BListGroupItem
  },
  directives: {
    Ripple
  },

  model: {
    prop: 'isManageLeaveRequestSidebarActive',
    event: 'update:is-manage-leave-request-sidebar-active'
  },
  props: {
    isManageLeaveRequestSidebarActive: {
      type: Boolean
    }
  },
  data () {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const minDate = new Date(today)
    minDate.setMonth(minDate.getMonth())
    minDate.setDate(minDate.getDate())
    return {
      errorLeaveCountPerYear: false,
      staffListData: [],
      usedLeaveCount: [],
      id: 'false',
      min: minDate,
      required,
      date: '',
      tabIndex: 0,
      rolesOption: [],
      assignedJobRoles: [],
      isTrackLeaveOnDays: false,
      isStartDateDisabled: false,
      isStartDatEnabled: false,
      isIncludeBankHolidays: false,
      startTime: '',
      endTime: '',
      allowMaxLeaveForAll: false,
      maxLeaveForAll: 0,
      alphaNum,
      noOfLeaves: '',
      workingHours: '',
      carryForwardCount: 0,
      carryEndDate: '',
      formLoading: false,
      allowUnlimitedLeave: false,
      allowCarryOutLeave: false,
      items: [],
      nextTodoId: 2,
      selectedRole: '',
      jobRoleItems: [],
      leaveItems: [
        {
          id: 1,
          startTime: '',
          endTime: '',
          reason: ''
        }
      ],
      filters: {},
      filter: null,
      filterOn: [],
      nextTodoIdJobRole: 1
    }
  },
  watch: {
    isManageLeaveRequestSidebarActive (val) {
      if (val) {
        this.getManageLeaveDetails()
      }
    },
    date (val) {
      let date = new Date(val)
      let year = date.getFullYear()
      let month = date.getMonth()
      let day = date.getDate()
      let endDate = new Date(year + 1, month, day - 1)
      endDate = this.momentFormat(endDate, 'DD-MMM')
      this.carryEndDate = endDate
    },
    // isTrackLeaveOnDays () {
    //   if (this.isTrackLeaveOnDays) {
    //     this.noOfLeaves = this.noOfLeaves / this.workingHours
    //   } else {
    //     this.noOfLeaves = this.noOfLeaves * this.workingHours
    //   }
    // },

    noOfLeaves () {
      this.checkEditable()

    },
    isTrackLeaveOnDays(){
      this.noOfLeaves = this.isTrackLeaveOnDays?(this.noOfLeaves / this.workingHours).toFixed(1):
        (this.noOfLeaves * this.workingHours).toFixed(1)
      
    }
  },
  methods: {

    checkEditable () {
      let max = Math.max(...this.usedLeaveCount)
      if (this.isTrackLeaveOnDays === true) {
        this.errorLeaveCountPerYear = this.noOfLeaves <= max
      } else {
        this.errorLeaveCountPerYear = max >= (this.noOfLeaves / this.workingHours).toFixed(2)
      }
    },
    async staffList () {
      try {
        const Response = await leaveManagementAdmin.staffList(this.filterQuery, 'salaried-staff')
        Response.data.data.forEach((x) => {
          this.usedLeaveCount.push(((x.number_of_leaves_per_year * x.practice.working_hours_per_day) - x.leave_takable_count) / x.practice.working_hours_per_day)
        })
        // this.staffListData = Response.data.data.map((x) => ({
        //   alocated_leave: x.practice.track_leave_on_days ? x.number_of_leaves_per_year : parseFloat(x.number_of_leaves_per_year * x.practice.working_hours_per_day).toFixed(1),
        //   used_days: ((x.number_of_leaves_per_year * x.practice.working_hours_per_day) - x.leave_takable_count) / x.practice.working_hours_per_day,
        //   remaining_days: x.number_of_leaves_per_year - ((x.number_of_leaves_per_year * x.practice.working_hours_per_day) - x.leave_takable_count) / x.practice.working_hours_per_day,
        //   working_hours_per_day: x.practice.working_hours_per_day,
        //   track_leave_on_day: !!x.practice.track_leave_on_days
        // }))
        // return this.staffListData
      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },

    toggle () {
      this.isManageLeaveRequestSidebarActive = false
    },
    repeateAgain () {
      this.leaveItems.push({
        id: (this.nextTodoId += 1)
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },

    repeateAgainJobRole () {
      if (this.assignedJobRoles.length !== 0) {
        let array_e = this.assignedJobRoles  //products array

        array_e.forEach((element) => {
          var obj = _.find(array_e, (find) => find.job_role_id == this.selectedRole.id)
          if (!obj) {
            this.id = true
          } else {
            this.id = false
          }
        })
      } else {
        this.id = true
      }
      if (this.id === true) {
        try {
          this.assignedJobRoles.push({
            id: 'new_' + this.nextTodoIdJobRole,
            job_role_id: this.selectedRole.id,
            role: this.selectedRole.role,
            max_count: ''
          })

          this.nextTodoIdJobRole++

          this.$nextTick(() => {
            this.trAddHeight(this.$refs.rowJobRole[0].offsetHeight)
          })
        } catch (error) {
          this, convertAndNotifyError(error)
        }
      }
    },
    removeItem (index) {
      this.leaveItems.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    removeItem1 (index) {
      this.repeateAgain()
      this.leaveItems.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)

    },

    removeRoleItem (index) {
      this.assignedJobRoles.splice(index, 1)
      this.trTrimHeight(this.$refs.rowJobRole[0].offsetHeight)
    },
    initTrHeight () {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },

    async manageLeave () {
      let arr = this.leaveItems
      let array_e = this.leaveItems
      this.id = 'false'

      array_e.forEach((value, index) => {

        arr.forEach((val, ind) => {

          var obj = ((value.startTime == val.startTime && value.endTime == val.endTime && index == ind) || (value.startTime > val.startTime && value.startTime > val.endTime && index != ind) || (value.endTime < val.startTime && value.endTime < val.endTime && index != ind))

          if (!obj) {
            this.id = 'true'
          }
        })
      })

      this.id === 'false'
      await this.manageLeaveRequest(this.id)

    },

    async manageLeaveRequest (id) {
      if (id === 'false') {
        if (await this.$refs.refFormObserver.validate()) {
          try {
            let formData = new FormData()
            let payload = {}
            let totalLeaveDays = 0
            if (this.isTrackLeaveOnDays) {
              totalLeaveDays = this.noOfLeaves
            } else {
              totalLeaveDays = this.noOfLeaves / this.workingHours
            }

            if (this.leaveItems[0].startTime) {
              let leaves = []
              leaves = this.leaveItems.map((x) => ({
                    start: moment(x.startTime).add(12, 'hours').utc().format('YYYY-MM-DD HH:mm:ss'),
                    end: moment(x.endTime).utc().add(12, 'hours').format('YYYY-MM-DD HH:mm:ss'),
                    reason: x.reason ? x.reason : ''
                  })
              )

              payload.leaveItems = leaves
              payload.maxLeaveRoleItems = this.assignedJobRoles
              payload.allowMaxLeaveForAll = this.allowMaxLeaveForAll
              payload.maxLeaveForAllCount = this.maxLeaveForAll
              payload.date = this.date
              payload.allow_unlimed_leave = this.allowUnlimitedLeave
              payload.number_of_leaves_per_year = totalLeaveDays
              payload.allow_carry_out_leave = this.allowCarryOutLeave
              payload.include_bank_holidays = this.isIncludeBankHolidays
              payload.is_track_leave_on_days = this.isTrackLeaveOnDays
              payload.carry_forward_count = this.carryForwardCount
              payload.working_hours_per_day = this.workingHours
              payload.is_day = true

            } else {

              payload.leaveItems = []
              payload.maxLeaveRoleItems = this.assignedJobRoles
              payload.allowMaxLeaveForAll = this.allowMaxLeaveForAll
              payload.maxLeaveForAllCount = this.maxLeaveForAll
              payload.date = this.date
              payload.allow_unlimed_leave = this.allowUnlimitedLeave
              payload.number_of_leaves_per_year = totalLeaveDays
              payload.allow_carry_out_leave = this.allowCarryOutLeave
              payload.include_bank_holidays = this.isIncludeBankHolidays
              payload.is_track_leave_on_days = this.isTrackLeaveOnDays
              payload.carry_forward_count = this.carryForwardCount
              payload.working_hours_per_day = this.workingHours
              payload.is_day = true

            }
            await AdminLeaveAPI.manageLeaveRequest(payload)
            this.showSuccessMessage('Request saved successfully')
            this.$emit('toggleSideBars')
          } catch (error) {
            if (error === 'Illegal operator and value combination.') {
              this.showErrorMessage('Please add Leave year start date')
              this.$emit('toggleSideBars')
            } else {
              this.convertAndNotifyError(error)
            }
          }
        }
        await this.addRole()
      } else {
        this.showErrorMessage('Please select a valid Locked date')
      }
    },
    changeStartDateDisabledState () {
      this.$swal({
        title: 'Are you sure you want to change the leave year start date?',
        text: 'All leave calculations and carry forwards are based on this date. Changing the date will reset everything.',
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Yes, change it',
        cancelButtonText: 'No, go back',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async (result) => {
        if (result.value) {
          this.isStartDateDisabled = false
          this.isStartDatEnabled = true
        }
      })
    },
    async getManageLeaveDetails () {
      try {
        this.formLoading = true
        const Response = await AdminLeaveAPI.manageLeaveDetails()
        let date = Response.data.data.practice_details[0].leave_year_start_date
        if (date !== '0000-00-00 00:00:00') {
          this.date = this.momentFormat(date, 'YYYY-MM-DD')
          this.isStartDateDisabled = true
          this.isStartDatEnabled = false
        } else {
          this.isStartDateDisabled = false
          this.isStartDatEnabled = true
        }
        let allowed_value =
            Response.data.data.practice_details[0].allow_unlimed_leave
        if (allowed_value) {
          this.allowUnlimitedLeave = true
        } else {
          this.allowUnlimitedLeave = false
        }
        let carryCount =
            Response.data.data.practice_details[0].leave_carry_forward_count
        if (carryCount) {
          this.carryForwardCount = carryCount
        } else {
          this.carryForwardCount = 0
        }

        this.workingHours = Response.data.data.practice_details[0].working_hours_per_day
        this.maxLeaveForAll = Response.data.data.practice_details[0].max_leave_for_all_count
        this.allowMaxLeaveForAll = !!Response.data.data.practice_details[0].allow_max_leave_for_all
        this.isIncludeBankHolidays = !!Response.data.data.practice_details[0].include_bank_holidays
        this.isTrackLeaveOnDays = !!Response.data.data.practice_details[0].track_leave_on_days
        let no_of_leaves = Response.data.data.practice_details[0].number_of_leaves_per_year
        let working_hours = Response.data.data.practice_details[0].working_hours_per_day
        if (this.isTrackLeaveOnDays) {
          this.noOfLeaves = no_of_leaves
        } else {
          this.noOfLeaves = parseFloat(no_of_leaves * working_hours).toFixed(1)
        }

        console.log(this.noOfLeaves)
        let pracLeaves = []
        pracLeaves = Response.data.data.no_leave_dates.map((x) => ({
          startTime: this.momentFormat(x.start, 'YYYY-MM-DD'),
          endTime: this.momentFormat(x.end, 'YYYY-MM-DD'),
          reason: x.reason
        }))
        if (pracLeaves.length > 0) {
          this.leaveItems = pracLeaves
        }
        await this.getAssignedJobRole()
        this.formLoading = false
      } catch (error) {
        this.formLoading = false
        this.convertAndNotifyError(error)
      }
    },
    async addRole () {
      try {
        const notAssignee = await AdminLeaveAPI.jobRoleNotAssignedList(
            this.filterQuery
        )
        this.rolesOption = notAssignee.data.data.map((x) => ({
          id: x.id,
          role: x.name
        }))
      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },
    async getAssignedJobRole () {
      try {
        const Response = await AdminLeaveAPI.getAssignedJobRoles(
            this.filterQuery
        )
        this.assignedJobRoles = Response.data.data.map((x) => ({
          id: x.id,
          role: x.job_roles.name,
          job_role_id: x.job_role_id,
          max_count: x.max_leaves_per_day
        }))
      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },
    filterQueryUpdate () {
      this.addRole()
    },
    cancel () {
      this.getManageLeaveDetails()
    }
  },
  async mounted () {
    await this.getAssignedJobRole()
    await this.addRole()
    await this.staffList()
    this.noOfLeaves = ''
    //this.initTrHeight()
  }
  // created() {
  //   window.addEventListener('resize', this.initTrHeight)
  // },
  // destroyed() {
  //   window.removeEventListener('resize', this.initTrHeight)
  // },
}
</script>

<style lang="scss">
</style>
<style lang="scss" scoped>
#connection_table td:nth-child(2) {
  padding-left: 15px !important;
}

.pickrstyleActive {
  background-color: #efefef;
}

.pickrstyleDective {
  background-color: #ffffff;
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
